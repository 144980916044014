<template>
    <div class="col-lg-9">
        <!-- Card -->
        <div class="card mb-3 mb-lg-5">
            <!-- Header -->
            <div class="card-header">
                <h5 class="card-header-title">My skills</h5>
            </div>
            <!-- End Header -->

            <!-- Body -->
            <div class="card-body">
            </div>
            <!-- End Body -->
        
        </div>
        <!-- End Card -->
    </div>
</template>